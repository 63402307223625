import React from "react";
import { FaReact } from "react-icons/fa";
import { AiOutlineHtml5 } from "react-icons/ai";
import { SiCplusplus } from "react-icons/si";
import { FaPython } from "react-icons/fa";
import logo from "../../assets/2001-1.jpg";
export const About = () => {
  return (
    <div
      id="about"
      className=" container  px-8 md:px-14 lg:px-24 mx-auto w-full mb-36"
    >
      <div className=" flex flex-col-reverse lg:grid lg:grid-cols-2  items-center pt-5">
        <div className="flex flex-col">
          <div className="px-5 md:mr-5">
            <img
              className=" rounded-lg"
              src={logo}
              alt="ich"
              style={{ maxWidth: "600px", width: "100%" }}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <div>
            <p className="text-xl text-center md:text-left">Stefan Thiele</p>
            <p className="pb-4 text-3xl text-center md:text-left text-selectedtext">
              Fachinformatiker für <br />
              Anwendungsentwicklung
            </p>
            <p className="lg:max-w-lg md:max-w-lg pb-4 text-center md:text-left">
              I design and develop services for customers of all sizes,
              specializing in creating stylish, modern websites, web services
              and online stores. My passion is to design digital user
              experiences through the bold interface and meaningful
              interactions.
            </p>
            <div className="grid grid-cols-2 gap-4 pb-4">
              <div>
                <div className="pb-4 text-center md:text-left">
                  <label>Age</label>
                  <p className="font-semibold text-lg">35 J</p>
                </div>
                <div className=" text-center md:text-left">
                  <label>Wohnort</label>
                  <p className="font-semibold text-lg">Düsseldorf, NRW</p>
                </div>
              </div>
              <div>
                <div className="pb-4 text-center md:text-left">
                  <label>E-Mail</label>
                  <p className="font-semibold text-lg">
                    kontakt@stefanthiele.net
                  </p>
                </div>
                <div className=" text-center md:text-left">
                  <label>Freelance</label>
                  <p className="font-semibold text-lg">Verfügbar</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-row justify-between mt-8 xl:mt-8 xl:px-24 px-6">
          <div className="flex flex-col items-center">
            <FaReact size={35} />
            <p>React JS</p>
          </div>
          <div className="flex flex-col items-center">
            <AiOutlineHtml5 size={35} />
            <p>HTML/CSS/JS</p>
          </div>
          <div className="flex flex-col items-center">
            <SiCplusplus size={35} />
            <p>C++</p>
          </div>
          <div className="flex flex-col items-center">
            <FaPython size={35} />
            <p>Python</p>
          </div>
        </div>
      </div>
    </div>
  );
};
